<template>
  <v-card outlined>
    <v-row
      class="d-flex justify-space-between"
      no-gutters
    >
      <v-col
        class="mb-0 py-0"
      >
        <v-skeleton-loader
          :loading="loading"
          type="list-item-avatar-two-line@3"
          fade-transition
        >
          <span>
            <v-list
              v-if="labResult.patient"
              class="pb-0"
              dense
            >
              <v-list-item selectable>
                <v-list-item-icon class="mr-4">
                  <v-icon size="19">
                    fal fa-fw fa-user
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="text-capitalize">{{ $tc('patient', 1) }}</v-list-item-subtitle>
                  <v-list-item-title data-private>
                    <patient-name
                      :patient="labResult.patient"
                    />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-icon class="mr-4">
                  <v-icon size="19">
                    fal fa-fw fa-calendar-day
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle
                    class="text-capitalize"
                    :class="{ 'mb-0': labResult.patient_age_bracket !== 'adult'}"
                  >
                    {{ $t('DOB') }}
                  </v-list-item-subtitle>
                  <v-list-item-title data-private>
                    <patient-dob
                      :patient="labResult.patient"
                      :age-date="labResult.created_at"
                      :age-bracket="labResult.patient_age_bracket"
                    />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-icon class="mr-4">
                  <patient-gender
                    :key="`${labResult.patient.gender}-icon`"
                    :patient="labResult.patient"
                    icon
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="text-capitalize">{{ $tc('gender', 1) }}</v-list-item-subtitle>
                  <v-list-item-title data-private>
                    <patient-gender
                      :key="`${labResult.patient.gender}-text`"
                      :patient="labResult.patient"
                      text
                    />
                    <v-chip
                      v-if="labResult.pregnant"
                      small
                      color="pink lighten-3"
                      :class="{ 'mb-1': $auth.check({ patients: 'phi' }) }"
                      class="ml-1 pr-2"
                    >
                      <v-icon
                        small
                        class="ml-n1 mr-1 py-2"
                      >
                        fas fa-person-pregnant
                      </v-icon>
                      Pregnant
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </span>
        </v-skeleton-loader>
      </v-col>
      <v-col
        class="mb-0 py-0"
      >
        <v-skeleton-loader
          :loading="loading"
          type="list-item-avatar-two-line@3"
          fade-transition
        >
          <span>
            <v-list
              v-if="labResult.patient"
              class="pb-0"
              dense
            >
              <v-list-item selectable>
                <v-list-item-icon class="mr-4">
                  <source-icon
                    :source="labResult.sample_source"
                    :size="19"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="text-capitalize">{{ $tc('specimen', 1) }}</v-list-item-subtitle>
                  <v-list-item-title>{{ (labResult.sample_type ? labResult.sample_type : labResult.sample_source) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-icon class="mr-4">
                  <v-icon size="19">
                    fal fa-fw fa-file-medical
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('Report Date') }}</v-list-item-subtitle>
                  <v-list-item-title>{{ labResult.created | moment("LL LT") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-icon class="mr-4">
                  <v-icon size="19">
                    fal fa-fw fa-exchange
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('Data Received') }}</v-list-item-subtitle>
                  <v-list-item-title>{{ labResult.created_at | moment("LL LT") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </span>
        </v-skeleton-loader>
      </v-col>
      <v-col
        class="mb-0 py-0"
        max="5"
      >
        <v-skeleton-loader
          :loading="loading"
          type="list-item-avatar-two-line@3"
          fade-transition
        >
          <span>
            <v-list
              v-if="labResult.patient"
              class="pb-0"
              dense
            >
              <v-list-item
                v-if="labResult.provider"
                selectable
              >
                <v-list-item-icon class="mr-4">
                  <v-icon size="19">
                    fal fa-fw fa-user-md
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>Provider</v-list-item-subtitle>
                  <v-list-item-title>{{ labResult.provider.first_name }} {{ labResult.provider.last_name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-icon class="mr-4">
                  <v-icon size="19">
                    fal fa-fw fa-hospital
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="text-capitalize">{{ $tc('facility', 1) }}</v-list-item-subtitle>
                  <v-list-item-title
                    v-if="labResult.facility"
                  >
                    {{ labResult.facility.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-icon class="mr-4">
                  <v-icon size="19">
                    fal fa-fw fa-diagnoses
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-list-item-subtitle class="text-capitalize">{{ $tc('diagnosis', labResult.diagnoses?.length) }}</v-list-item-subtitle>
                  </v-list-item-subtitle>
                  <span
                    v-if="labResult.diagnoses && labResult.diagnoses.length"
                    style="white-space:normal"
                  >
                    <diagnosis-chip
                      v-for="(diagnosis, key) in labResult.diagnoses"
                      :key="key"
                      :diagnosis="diagnosis.diagnosis_code"
                      :category="diagnosis.category_code"
                      :icd10="diagnosis.icd_10_code"
                      :text="diagnosis.abbreviated"
                      :group="diagnosis.group_id"
                      :tooltip="shortDiagnosis"
                      highlight-unclassified
                    />
                  </span>
                  <v-list-item-title v-else>
                    None Reported
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </span>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
  export default {
    components: {
      PatientName: () => import('@/components/patient/PatientName.vue'),
      PatientDob: () => import('@/components/patient/PatientDOB.vue'),
      PatientGender: () => import('@/components/patient/PatientGender.vue'),
      SourceIcon: () => import('@/components/source/SourceIcon.vue'),
      DiagnosisChip: () => import('@/components/DiagnosisChip.vue'),
    },
    props: {
      labResult: {
        type: Object,
        default: () => ({}),
      },
      loading: {
        type: Boolean,
        default: false,
      },
      shortDiagnosis: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
